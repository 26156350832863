<template>
  <div class="popup_wrap" style="width:750px;">
    <button class="layer_close" @click="$emit('close')">close</button>
    <div class="popup_cont" ><!-- popup_cont -->
      <h1 class="page_title">{{ $t('msg.ONEX050T010.031') }}</h1> <!-- Certificate 입력안내 -->
      <div class="content_box"><!-- content_box -->
        <div>
          <p>{{ $t('msg.ONEX050T010.033') }}</p> <!-- * 입력방법 -->
          <p>{{ $t('msg.ONEX050T010.034') }}</p> <!-- 1. 기존에 발행 요청 하셨던 Certificate no.를 알고 계시거나, 저장하신 Certificate가 있으신 경우. -->
          <p>{{ $t('msg.ONEX050T010.035') }}</p> <!-- A. 기존 Certificate Ref. No.란에 번호를 입력하시거나, Template 을 선택하시고 적용 버튼을 클릭하시면 해당 내용을 편집해서 이용하실 수 있습니다. -->
          <p>{{ $t('msg.ONEX050T010.036') }}</p> <!-- 2. 선사가 승인한 문구를 이용하고자 하시는 경우 -->
          <p>{{ $t('msg.ONEX050T010.037') }}</p> <!-- A. 쉬운 Certificate 입력 버튼을 클릭하시고, 원하시는 내용을 선택하시면 해당 문구가 입력됩니다. 원하시는 대로 편집 후 이용하실 수 있습니다. -->
          <p>{{ $t('msg.ONEX050T010.038') }}</p> <!-- 3. 특정 문구의 입력가능여부를 확인하고자 하실 경우 -->
          <p>{{ $t('msg.ONEX050T010.039') }}</p> <!-- A. 요청사항 항목에 문의내용을 기록하여 저장하시면, 담당자가 확인 후 결과를 알려드립니다. -->
          <p>{{ $t('msg.ONEX050T010.040') }}</p> <!-- 4. 비엘 발행 완료 후 Certificate 에 선사 오토사인 날인됩니다. -->
          <p>{{ $t('msg.ONEX050T010.041') }}</p> <!-- 5. Certificate 발행 비용은 별도로 없고, 승인 완료 후 출력하여 사용하시면 됩니다. -->
          <br>
          <p>{{ $t('msg.ONEX050T010.042') }}</p> <!-- * 불러오기 기능 -->
          <p>{{ $t('msg.ONEX050T010.043') }}</p> <!-- - 유사한 내용의 Certificate을 반복해서 발행하시는 화주의 경우, 불러오기 기능을 이용하여 편리하게 Certificate을 발행하실 수 있습니다. -->
        </div>
      </div><!-- content_box // -->
    </div><!-- popup_cont -->
  </div><!-- popup_wrap // -->
</template>

<script>

export default {
  name: 'CertificateGuidePop',
  data: function () {
    return {
    }
  },
  created () {
  },
  methods: {
  }
}
</script>
