var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "popup_wrap", staticStyle: { width: "750px" } },
    [
      _c(
        "button",
        {
          staticClass: "layer_close",
          on: {
            click: function ($event) {
              return _vm.$emit("close")
            },
          },
        },
        [_vm._v("close")]
      ),
      _c("div", { staticClass: "popup_cont" }, [
        _c("h1", { staticClass: "page_title" }, [
          _vm._v(_vm._s(_vm.$t("msg.ONEX050T010.031"))),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "content_box" }, [
          _c("div", [
            _c("p", [_vm._v(_vm._s(_vm.$t("msg.ONEX050T010.033")))]),
            _vm._v(" "),
            _c("p", [_vm._v(_vm._s(_vm.$t("msg.ONEX050T010.034")))]),
            _vm._v(" "),
            _c("p", [_vm._v(_vm._s(_vm.$t("msg.ONEX050T010.035")))]),
            _vm._v(" "),
            _c("p", [_vm._v(_vm._s(_vm.$t("msg.ONEX050T010.036")))]),
            _vm._v(" "),
            _c("p", [_vm._v(_vm._s(_vm.$t("msg.ONEX050T010.037")))]),
            _vm._v(" "),
            _c("p", [_vm._v(_vm._s(_vm.$t("msg.ONEX050T010.038")))]),
            _vm._v(" "),
            _c("p", [_vm._v(_vm._s(_vm.$t("msg.ONEX050T010.039")))]),
            _vm._v(" "),
            _c("p", [_vm._v(_vm._s(_vm.$t("msg.ONEX050T010.040")))]),
            _vm._v(" "),
            _c("p", [_vm._v(_vm._s(_vm.$t("msg.ONEX050T010.041")))]),
            _vm._v(" "),
            _c("br"),
            _c("p", [_vm._v(_vm._s(_vm.$t("msg.ONEX050T010.042")))]),
            _vm._v(" "),
            _c("p", [_vm._v(_vm._s(_vm.$t("msg.ONEX050T010.043")))]),
          ]),
        ]),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }